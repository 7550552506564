.amplify-button[data-variation='primary'] {
  background: #005288;
}

.amplify-tabs-item[data-state=active] {
  color: #005288;
  border-color: #005288;
}

.amplify-tabs-item:hover {
  color: #005288;
}

.amplify-button--link {
  color: #005288;
}
